import { gql } from '@apollo/client/core';

export const getEnumStatuses = gql`
  query getEnumStatuses {
    deviceConnectionStatusEnums {
      __typename
      value
      comment
    }
    streamStatusEnums {
      __typename
      value
      comment
    }
    objectActionEnums {
      __typename
      value
      comment
    }
    doorModeEnums {
      __typename
      value
      comment
    }
    doorStateEnums {
      __typename
      value
      comment
    }
    doorAlarmEnums {
      __typename
      value
      comment
    }
    accessDeniedReasonEnums {
      __typename
      value
      comment
    }
    batteryLevelEnums {
      __typename
      value
      comment
    }
  }
`;
