import { gql } from '@apollo/client/core';

export const PlaceFragment = gql`
  fragment PlaceFragment on qx_Place {
    id
    qxCustomerID
    accountNumber
    name
    type
    path
    canSelfServe
    requireTwoFactor
    defaultView {
      id
      name
    }
    timezone
    isLockedDown
    parentPlaceID
    parentPlace {
      id
      name
      hierarchy {
        name
      }
      type
      path
      timezone
    }
    addresses
    # permissions {
    #   id
    # }
    gateway {
      # ...GatewayFragment
      id
      name
      connectionStatus
      syncStatus
      place {
        id
      }
    }
    toHomePlaces {
      customerPlaceID
      customerPlace {
        id
        name
        type
        accountNumber
        anniversaryDate
        billingPeriodMonths
        canSelfServe
      }
      homePlace {
        id
        name
        type
        path
        accountNumber
        anniversaryDate
        billingPeriodMonths
        canSelfServe
      }
    }
    children {
      id
      name
      type
      path
      gateway {
        # ...GatewayFragment
        id
        name
        connectionStatus
        syncStatus
      }
      addresses
      parentPlace {
        id
        name
        type
      }
      children {
        id
        path
        gateway {
          # ...GatewayFragment
          id
          name
          connectionStatus
          syncStatus
        }
        addresses
      }
    }
  }
`;

export const PlaceSearchFragment = gql`
  fragment PlaceSearchFragment on qx_Place {
    id
    name
    hierarchy {
      name
    }
  }
`;

export const PlaceBillingFragment = gql`
  fragment PlaceBillingFragment on qx_Place {
    anniversaryDate
    billingStartDate
    billingPeriodMonths
  }
`;

/**
 * Used by state-store to subscribe to the places starting at the
 * User's current scope. Used by the Ui to determine whether we
 * should refresh the UI on the User or log them out.
 */
export const StateStoreSubscribeToPlaceById = gql`
  subscription StateStoreSubscribeToPlaceById($id: String!) {
    place(id: $id) {
      id
      name
      type
      path
      isLockedDown
    }
  }
`;

export const GQL_CREATE_PLACE = gql`
  mutation GQL_CREATE_PLACE($place: qx_Place_insert_input!) {
    createPlaces(objects: [$place]) {
      returning {
        id
        name
        parentPlace {
          id
          name
          type
        }
      }
    }
  }
`;

export const GQL_UPDATE_PLACE_BY_ID = gql`
  mutation GQL_UPDATE_PLACE_BY_ID($id: String!, $data: qx_Place_set_input!) {
    updatePlaces(where: { id: { _eq: $id } }, _set: $data) {
      returning {
        id
        name
        canSelfServe
        parentPlace {
          id
          name
          type
        }
      }
    }
  }
`;

export const GQL_DELETE_PLACE_BY_ID = gql`
  mutation GQL_DELETE_PLACE_BY_ID($id: String!) {
    deletePlace(id: $id) {
      id
      name
      type
    }
  }
`;

export const GQL_PLACE_SEARCH = gql`
  query GQL_PLACE_SEARCH($where: qx_Place_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Place_order_by!]) {
    places(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...PlaceSearchFragment
    }
    placeAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${PlaceSearchFragment}
`;

/**
 * Used for checking unique values under a customer,
 * but we first have to find the Customer Place ID.
 */
export const GQL_GET_CUSTOMER_PLACE_ID = gql`
  query GQL_GET_CUSTOMER_PLACE_ID($placeID: String!) {
    place(id: $placeID) {
      id
      toHomePlaces {
        homePlace {
          id
          path
          isLockedDown
          canSelfServe
        }
        customerPlaceID
        customerPlace {
          id
          path
          name
          type
          isLockedDown
          canSelfServe
        }
      }
    }
  }
`;

/**
 * Query a Single Place by ID
 */
export const GQL_GET_PLACE_BY_ID = gql`
  query GQL_GET_PLACE_BY_ID($id: String!) {
    place(id: $id) {
      ...PlaceFragment
      ...PlaceBillingFragment
    }
  }
  ${PlaceFragment}
  ${PlaceBillingFragment}
`;

/**
 * Subscribe to a Single Place by ID
 */
export const GQL_SUB_PLACE_BY_ID = gql`
  subscription GQL_SUB_PLACE_BY_ID($id: String!) {
    place(id: $id) {
      ...PlaceFragment
      ...PlaceBillingFragment
    }
  }
  ${PlaceFragment}
  ${PlaceBillingFragment}
`;

export const GQL_GET_PLACE_HOME_BY_ID = gql`
  query GQL_GET_PLACE_HOME_BY_ID($id: String!) {
    place(id: $id) {
      id
      toHomePlaces {
        homePlace {
          id
          type
          name
          canSelfServe
          path
        }
        integratorPlaceID
        customerPlaceID
        customerPlace {
          id
          type
          name
          canSelfServe
          path
        }
        integratorPlaceID
        integratorPlace {
          id
          type
          name
          path
        }
        customerPlace {
          id
          type
          name
          canSelfServe
          path
        }
        placeID
        place {
          id
          type
          name
          path
        }
      }
    }
  }
`;
