import { gql } from '@apollo/client/core';

export const GQL_GET_EVENT_TYPES_AND_STATUSES = gql`
  query GQL_GET_EVENT_TYPES_AND_STATUSES {
    eventTypes(order_by: { type: asc }) {
      id: type
      type
      statusTable
      statusValues(order_by: { value: asc }) {
        comment
        value
      }
    }
  }
`;
