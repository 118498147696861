import { gql } from '@apollo/client/core';

export const GatewaySearchFragment = gql`
  fragment GatewaySearchFragment on qx_Gateway {
    id
    name
    thumbnail
    thumbnail_md
    oldestMedia
    syncStatus
    licenseDetails
    features
    hostname
    place {
      id
      name
      hierarchy {
        name
      }
    }
    serial
    version
    wireguardIP
    devices {
      id
      kind
      doors {
        id
        enabled
      }
      videoSources {
        id
        enabled
      }
    }
    connectionStatus
  }
`;

export const GQL_CREATE_GATEWAY_BANDWIDTH_SCHEDULE = gql`
  mutation GQL_CREATE_GATEWAY_BANDWIDTH_SCHEDULE($bandwidthSchedule: qx_GatewayBandwidthSchedule_insert_input!) {
    createGatewayBandwidthSchedule(object: $bandwidthSchedule) {
      id
    }
  }
`;

export const GQL_UPDATE_GATEWAY_BANDWIDTH_SCHEDULE = gql`
  mutation GQL_UPDATE_GATEWAY_BANDWIDTH_SCHEDULE($id: bigint!, $data: qx_GatewayBandwidthSchedule_set_input!) {
    updateGatewayBandwidthSchedule(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const GQL_DELETE_GATEWAY_BANDWIDTH_SCHEDULE = gql`
  mutation GQL_DELETE_GATEWAY_BANDWIDTH_SCHEDULE($id: bigint!) {
    deleteGatewayBandwidthSchedule(id: $id) {
      id
    }
  }
`;
