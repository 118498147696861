import { gql } from '@apollo/client/core';

export const LockFragment = gql`
  fragment LockFragment on qx_Lock {
    name
    direction
    enabled
    type
    relayMonitor
    powerMonitor
  }
`;

export const GQL_GET_LOCKS_BY_DOOR_ID = gql`
  query GQL_GET_LOCKS_BY_DOOR_ID($doorId: bigint!) {
    locks(where: { doorID: { _eq: $doorId }, enabled: { _eq: true } }) {
      ...LockFragment
    }
  }
  ${LockFragment}
`;
