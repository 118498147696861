import { gql } from '@apollo/client/core';
import { DoorSearchFragment } from './doors';
import { CameraSearchFragment } from './cameras';
import { GatewaySearchFragment } from './gateways';

export const RuleFragment = gql`
  fragment RuleFragment on qx_Flow {
    id
    name
    description
    enabled
    status
    flow
    placeID
    place {
      id
      name
      type
      hierarchy {
        name
      }
    }
    scheduleID
    schedule {
      id
      name
      description
      place {
        id
        name
      }
      blocks {
        id
        date
        duration
        weekdays
        toHolidaySets {
          holidaySetID
          scheduleBlockID
          holidaySet {
            id
            name
          }
        }
      }
    }
  }
`;

export const GQL_GET_RULE_FORM = gql`
  query GQL_GET_RULE_FORM($id: bigint!) {
    flow(id: $id) {
      ...RuleFragment
    }
  }
  ${RuleFragment}
`;

export const GQL_RULE_SEARCH = gql`
  query GQL_RULE_SEARCH($where: qx_Flow_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Flow_order_by!]) {
    flows(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...RuleFragment
    }
    flowAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${RuleFragment}
`;

export const GQL_RULE_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_RULE_SEARCH_SUBSCRIPTION(
    $where: qx_Flow_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Flow_order_by!]
  ) {
    flows(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...RuleFragment
    }
  }
  ${RuleFragment}
`;

export const GQL_CREATE_RULE = gql`
  mutation GQL_CREATE_RULE($rule: qx_Flow_insert_input!) {
    createFlow(object: $rule) {
      ...RuleFragment
    }
  }
  ${RuleFragment}
`;

export const GQL_UPDATE_RULE_BY_ID = gql`
  mutation GQL_UPDATE_RULE_BY_ID($id: bigint!, $rule: qx_Flow_set_input!) {
    updateFlow(pk_columns: { id: $id }, _set: $rule) {
      ...RuleFragment
    }
  }
  ${RuleFragment}
`;

export const GQL_DELETE_RULE_BY_ID = gql`
  mutation GQL_DELETE_RULE_BY_ID($id: bigint!) {
    deleteFlow(id: $id) {
      id
      name
    }
  }
`;

export const GQL_GET_RULE_ACTIONS = gql`
  query GQL_GET_RULE_ACTIONS {
    getFlowActions {
      name
      environments
      arguments
    }
  }
`;

export const GQL_GET_RULE_FIRING_DATA = gql`
  query GQL_GET_RULE_FIRING_DATA($ruleID: bigint!) {
    getFlowFiringData(flowID: $ruleID) {
      output
    }
  }
`;

export const GQL_RULE_CLEAR_CACHE = gql`
  mutation GQL_RULE_CLEAR_CACHE($ruleID: bigint!) {
    clearFlowStatus(flowID: $ruleID) {
      success
    }
  }
`;

const ToHomePlacesCustomerPlaceFragment = gql`
  fragment ToHomePlacesCustomerPlaceFragment on qx_Place {
    id
    name
    toHomePlaces {
      homePlace {
        id
        name
        canSelfServe
      }
    }
  }
`

export const GQL_GET_RULE_FIRING_DATA_MAP = gql`
  query GQL_GET_RULE_FIRING_DATA_MAP(
    $deviceID: [String!] = []
    $personID: [String!] = []
    $doorID: [bigint!] = []
    $gatewayID: [String!] = []
    $placeID: [String!] = []
    $videoSourceID: [bigint!] = []
    $mediaProfileID: [bigint!] = []
  ) {
    doors(where: {
      _or: [
        { id: { _in: $doorID } },
        { deviceID: { _in: $deviceID } },
      ]
    }) {
      ...DoorSearchFragment
      place {
        ...ToHomePlacesCustomerPlaceFragment
      }
    }
    places(where: { id: { _in: $placeID } }) {
      isLockedDown
      gateway {
        id
        name
        connectionStatus
        syncStatus
      }
      ...ToHomePlacesCustomerPlaceFragment
    }
    gateways(where: { id: { _in: $gatewayID } }) {
      ...GatewaySearchFragment
      place {
        ...ToHomePlacesCustomerPlaceFragment
      }
    }
    people(where: { id: { _in: $personID } }) {
      id
      firstName
      lastName
      avatar_sm
      place {
        ...ToHomePlacesCustomerPlaceFragment
      }
    }
    videoSources(where: {
      _or: [
        { id: { _in: $videoSourceID } },
        { deviceID: { _in: $deviceID } },
        { mediaProfiles: { id: { _in: $mediaProfileID } } },
      ]
    }) {
      ...CameraSearchFragment
      place {
        ...ToHomePlacesCustomerPlaceFragment
      }
    }
  }
  ${DoorSearchFragment}
  ${CameraSearchFragment}
  ${GatewaySearchFragment}
  ${ToHomePlacesCustomerPlaceFragment}
`;
