import { gql } from '@apollo/client/core';

export const HolidaySetFragment = gql`
  fragment HolidaySetFragment on qx_HolidaySet {
    id
    name
    description
    placeID
    place {
      id
      type
      name
      hierarchy {
        name
      }
    }
    dates
  }
`;

export const getHolidaySets = gql`
  query getHolidaySets($path: String!) {
    holidaySets(where: { place: { path: { _like: $path } } }) {
      ...HolidaySetFragment
    }
  }
  ${HolidaySetFragment}
`;

export const subscribeToAllHolidaySetsUpdates = gql`
  subscription subscribeToAllHolidaySetsUpdates($path: String!) {
    holidaySets(where: { place: { path: { _like: $path } } }) {
      ...HolidaySetFragment
    }
  }
  ${HolidaySetFragment}
`;

export const getHolidaySetById = gql`
  query getHolidaySetById($id: String!) {
    holidaySet(id: $id) {
      ...HolidaySetFragment
    }
  }
  ${HolidaySetFragment}
`;

export const GQL_SUB_HOLIDAY_SET_BY_ID = gql`
  subscription GQL_SUB_HOLIDAY_SET_BY_ID($id: String!) {
    holidaySet(id: $id) {
      ...HolidaySetFragment
    }
  }
  ${HolidaySetFragment}
`;

export const updateHolidaySetById = gql`
  mutation updateHolidaySetById($id: String!, $holidaySet: qx_HolidaySet_set_input!) {
    updateHolidaySets(where: { id: { _eq: $id } }, _set: $holidaySet) {
      returning {
        ...HolidaySetFragment
      }
    }
  }
  ${HolidaySetFragment}
`;

export const GQL_HOLIDAY_SET_SEARCH = gql`
  query GQL_HOLIDAY_SET_SEARCH(
    $where: qx_HolidaySet_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_HolidaySet_order_by!]
  ) {
    holidaySets(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...HolidaySetFragment
    }
    holidaySetAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${HolidaySetFragment}
`;

export const GQL_HOLIDAY_SET_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_HOLIDAY_SET_SEARCH_SUBSCRIPTION(
    $where: qx_HolidaySet_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_HolidaySet_order_by!]
  ) {
    holidaySets(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...HolidaySetFragment
    }
  }
  ${HolidaySetFragment}
`;
