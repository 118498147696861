import { gql } from '@apollo/client/core';

const GenericDeviceFragment = gql`
  fragment GenericDeviceFragment on qx_Device {
    id
    kind
    name
    description
    oldestMedia {
      type
      date
    }
    gatewayID
    gateway {
      id
      name
      connectionStatus
      syncStatus
      placeID
      place {
        id
        name
        hierarchy {
          name
        }
      }
    }
    doors(order_by: { name: asc }) {
      id
      name
      enabled
      canOperate
      # used by children table panel on device page
      device {
        id
        deviceAddress
      }
      # used by children table panel on device page
      place {
        id
        name
        hierarchy {
          name
        }
      }
      outside {
        id
        name
        hierarchy {
          name
        }
      }
    }
    videoSources(order_by: { name: asc }) {
      id
      name
      enabled
      # used by children table panel on device page
      device {
        id
        deviceAddress
      }
      # used by children table panel on device page
      place {
        id
        name
        hierarchy {
          name
        }
      }
    }
    port
    tls
    make
    model
    serial
    deviceMac
    deviceAddress
    deviceUsername
    devicePassword
    firmware
    pacsConfigurationID
    meta
    connectionStatus
    createdAt
    pacsStatus
    pacsHWUpdate
  }
`;

export const DeviceSearchFragment = gql`
  fragment DeviceSearchFragment on qx_Device {
    id
    kind
    name
    description
    deviceAddress
    deviceMac
    port
    serial
    tls
    meta
    make
    model
    firmware
    ntp
    timezone
    dst
    pacsStatus
    pacsHWUpdate
    connectionStatus
    gateway {
      id
      name
      connectionStatus
      syncStatus
      place {
        id
        name
        hierarchy {
          name
        }
      }
    }
    doors {
      id
      name
      enabled
    }
    videoSources {
      id
      name
      enabled
    }
  }
`;

export const GQL_GET_DEVICE_BY_ID = gql`
  query GQL_GET_DEVICE_BY_ID($id: String!) {
    device(id: $id) {
      ...GenericDeviceFragment
    }
  }
  ${GenericDeviceFragment}
`;

export const GQL_SUB_DEVICE_BY_ID = gql`
  subscription GQL_SUB_DEVICE_BY_ID($id: String!) {
    device(id: $id) {
      ...GenericDeviceFragment
    }
  }
  ${GenericDeviceFragment}
`;

export const GQL_UPDATE_DEVICE_BY_ID = gql`
  mutation GQL_UPDATE_DEVICE_BY_ID($id: String!, $device: qx_Device_set_input) {
    updateDevice(pk_columns: { id: $id }, _set: $device) {
      id
    }
  }
`;

export const GQL_GET_DEVICE_PROXY_URL = gql`
  query GQL_GET_DEVICE_PROXY_URL($id: String!) {
    device(id: $id) {
      id
      proxyUrl
    }
  }
`;

export const GQL_CHECK_DEVICES_FOR_UNIQUE_GATEWAY_IP_PORT = gql`
  query GQL_CHECK_DEVICES_FOR_UNIQUE_GATEWAY_IP_PORT($where: qx_Device_bool_exp) {
    devices(where: $where) {
      id
    }
  }
`;

export const GQL_DEVICE_SEARCH = gql`
  query GQL_DEVICE_SEARCH($where: qx_Device_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Device_order_by!]) {
    devices(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...DeviceSearchFragment
    }
    deviceAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${DeviceSearchFragment}
`;

export const GQL_DEVICE_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_DEVICE_SEARCH_SUBSCRIPTION(
    $where: qx_Device_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Device_order_by!]
  ) {
    devices(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...DeviceSearchFragment
    }
  }
  ${DeviceSearchFragment}
`;
