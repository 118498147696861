import { gql } from '@apollo/client/core';

export const TimeZoneFragment = gql`
  fragment TimeZoneFragment on qx_TimeZone {
    name
    description
    iana
    tzoffset
  }
`;

export const GQL_GET_TIMEZONES = gql`
  query GQL_GET_TIMEZONES {
    timeZones(order_by: [{ tzoffset: asc }]) {
      ...TimeZoneFragment
    }
  }
  ${TimeZoneFragment}
`;
