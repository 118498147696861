import { gql } from '@apollo/client/core';

export const SavedClipFragment = gql`
  fragment SavedClipFragment on qx_Clip {
    id
    name
    progress
    duration
    startTime
    endTime
    expiresAt
    placeID
    cameraInfo
    base64
    person {
      id
      firstName
      lastName
      avatar_sm
    }
    description
    place {
      id
      name
      hierarchy {
        name
      }
    }
    createdBy
    type
    eClipType {
      value
      comment
    }
    status
    eClipStatus {
      value
      comment
    }
    size
    checksum
    videoSourceID
    auditLog_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const SavedClipSearchFragment = gql`
  fragment SavedClipSearchFragment on qx_Clip {
    id
    name
    progress
    duration
    startTime
    expiresAt
    createdAt
    cameraInfo
    description
    placeID
    place {
      id
      name
      hierarchy {
        name
      }
    }
    status
    size
    base64
    error
    checksum
    auditLog_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const SavedClipFormFragment = gql`
  fragment SavedClipFormFragment on qx_Clip {
    id
    name
    expiresAt
    description
  }
`;

export const SavedClipAuditLogPersonClipFragment = gql`
  fragment SavedClipAuditLogPersonClipFragment on qx_Person {
    firstName
    lastName
    email
    employeeID
    avatar_sm
  }
`;

export const SavedClipAuditLogFragment = gql`
  fragment SavedClipAuditLogFragment on qx_ClipAuditLog {
    id
    personID
    person {
      ...SavedClipAuditLogPersonClipFragment
    }
    alternateID
    action
    changes
    createdAt
    changes
  }
  ${SavedClipAuditLogPersonClipFragment}
`;

export const SavedClipSharePersonSearchFragment = gql`
  fragment SavedClipSharePersonSearchFragment on qx_Person {
    id
    firstName
    lastName
    email
    employeeID
    avatar_md
  }
`;

export const SharedLinksFragment = gql`
  fragment SharedLinksFragment on qx_SharedLinks {
    id
    url
    expiresAt
    singleUse
    createdAt
    createdBy {
      id
      lastName
      firstName
      avatar_sm
    }
    ownerID
    emails
    persons
    clip {
      id
    }
    linkableType
    linkableID
  }
`;

export const GQL_SAVED_CLIP_SEARCH = gql`
  query GQL_SAVED_CLIP_SEARCH($where: qx_Clip_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Clip_order_by!]) {
    clips(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...SavedClipSearchFragment
    }
    clipAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${SavedClipSearchFragment}
`;

export const GQL_SAVED_CLIP_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_SAVED_CLIP_SEARCH_SUBSCRIPTION(
    $where: qx_Clip_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Clip_order_by!]
  ) {
    clips(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...SavedClipSearchFragment
    }
  }
  ${SavedClipSearchFragment}
`;

export const GQL_SAVED_CLIP_AUDIT_LOG_SEARCH = gql`
  query GQL_SAVED_CLIP_AUDIT_LOG_SEARCH(
    $where: qx_ClipAuditLog_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_ClipAuditLog_order_by!]
  ) {
    clipAuditLogs(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...SavedClipAuditLogFragment
    }
    clipAuditLogAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${SavedClipAuditLogFragment}
`;

export const GQL_SAVED_CLIP_AUDIT_LOG_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_SAVED_CLIP_AUDIT_LOG_SEARCH_SUBSCRIPTION(
    $where: qx_ClipAuditLog_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_ClipAuditLog_order_by!]
  ) {
    clipAuditLogs(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...SavedClipAuditLogFragment
    }
  }
  ${SavedClipAuditLogFragment}
`;

export const GQL_SAVED_CLIP_SHARED_LINKS = gql`
  query GQL_SAVED_CLIP_SHARED_LINKS($clipID: bigint!) {
    sharedLinks(where: { clip: { id: { _eq: $clipID } } }) {
      ...SharedLinksFragment
    }
  }
  ${SharedLinksFragment}
`;

// TODO :: switch to usePersonSearch
export const GQL_SAVED_CLIP_SHARE_PERSON_SEARCH = gql`
  query GQL_SAVED_CLIP_SHARE_PERSON_SEARCH(
    $where: qx_Person_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Person_order_by!]
  ) {
    people(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...SavedClipSharePersonSearchFragment
    }
    personAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${SavedClipSharePersonSearchFragment}
`;

// TODO :: switch to usePersonSearchSubscription
export const GQL_SAVED_CLIP_SHARE_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_SAVED_CLIP_SHARE_SEARCH_SUBSCRIPTION(
    $where: qx_Person_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Person_order_by!]
  ) {
    people(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...SavedClipSharePersonSearchFragment
    }
  }
  ${SavedClipSharePersonSearchFragment}
`;

export const GQL_GET_SHARED_LINK_BY_CLIP_ID_WITH_LIMIT = gql`
  query GQL_GET_SHARED_LINK_BY_CLIP_ID_WITH_LIMIT($clipID: bigint!, $limit: Int) {
    sharedLinks(where: { clip: { id: { _eq: $clipID } } }, limit: $limit) {
      id
      url
    }
  }
`;

export const GQL_GET_SHARED_LINK = gql`
  query GQL_GET_SHARED_LINK($linkID: bigint!) {
    sharedLink(id: $linkID) {
      ...SharedLinksFragment
    }
  }
  ${SharedLinksFragment}
`;
