import { gql } from '@apollo/client/core';

export const PermissionFragment = gql`
  fragment PermissionFragment on qx_Permission {
    id
    name
    thumbnail_lg
    group {
      id
      name
      description
      thumbnail
    }
    place {
      id
      name
    }
    schedule {
      id
      name
    }
  }
`;

export const GQL_CREATE_PERMISSION = gql`
  mutation GQL_CREATE_PERMISSION($permission: qx_Permission_insert_input!) {
    createPermission(object: $permission) {
      ...PermissionFragment
    }
  }
  ${PermissionFragment}
`;

export const getPermissionsData = gql`
  query getPermissionsData($path: String!) {
    permissions(where: { place: { path: { _like: $path } } }) {
      ...PermissionFragment
    }
  }
  ${PermissionFragment}
`;

export const getPermissionById = gql`
  query getPermissionById($id: String!) {
    permission(id: $id) {
      ...PermissionFragment
    }
  }
  ${PermissionFragment}
`;

export const subscribeToAllPermissionsUpdates = gql`
  subscription subscribeToAllPermissionsUpdates($path: String!) {
    permissions(where: { place: { path: { _like: $path } } }) {
      ...PermissionFragment
    }
  }
  ${PermissionFragment}
`;

export const SinglePermissionFragment = gql`
  fragment SinglePermissionFragment on qx_Permission {
    id
    place {
      id
      name
      path
      hierarchy {
        name
      }
    }
    group {
      id
      name
    }
    schedule {
      id
      name
    }
    thumbnail_lg
  }
`;

export const GQL_GET_PERMISSION_BY_ID = gql`
  query GQL_GET_PERMISSION_BY_ID($id: String!) {
    permission(id: $id) {
      ...SinglePermissionFragment
    }
  }
  ${SinglePermissionFragment}
`;

export const GQL_SUB_PERMISSION_BY_ID = gql`
  subscription GQL_SUB_PERMISSION_BY_ID($id: String!) {
    permission(id: $id) {
      ...SinglePermissionFragment
    }
  }
  ${SinglePermissionFragment}
`;

export const PermissionFormFragment = gql`
  fragment PermissionFormFragment on qx_Permission {
    id
    placeID
    place {
      id
      name
    }
    scheduleID
    schedule {
      id
      name
    }
    groupID
    group {
      id
      name
    }
    thumbnail
  }
`;

export const GQL_GET_PERMISSION_FORM = gql`
  query getPermissionForm($id: String!) {
    permission(id: $id) {
      ...PermissionFormFragment
    }
  }
  ${PermissionFormFragment}
`;

export const GQL_UPDATE_PERMISSION_BY_ID = gql`
  mutation updatePermissionById($id: String!, $permission: qx_Permission_set_input!) {
    updatePermission(_set: $permission, pk_columns: { id: $id }) {
      ...SinglePermissionFragment
    }
  }
  ${SinglePermissionFragment}
`;

export const GQL_DELETE_PERMISSION_BY_ID = gql`
  mutation deletePermission($id: String!) {
    deletePermission(id: $id) {
      id
    }
  }
`;

export const PermissionSearchFragment = gql`
  fragment PermissionSearchFragment on qx_Permission {
    id
    name
    thumbnail
    group {
      id
      name
      thumbnail
      toPeople_aggregate {
        aggregate {
          count
        }
      }
    }
    place {
      id
      name
      hierarchy {
        name
      }
    }
    schedule {
      id
      name
    }
  }
`;

export const GQL_PERMISSION_SEARCH = gql`
  query GQL_PERMISSION_SEARCH(
    $where: qx_Permission_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Permission_order_by!]
  ) {
    permissions(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...PermissionSearchFragment
    }
    permissionAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${PermissionSearchFragment}
`;

export const GQL_PERMISSION_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_PERMISSION_SEARCH_SUBSCRIPTION(
    $where: qx_Permission_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Permission_order_by!]
  ) {
    permissions(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...PermissionSearchFragment
    }
  }
  ${PermissionSearchFragment}
`;

export const GQL_CHECK_PERMISSION_IS_UNIQUE = gql`
  query checkForUniquePermission($id: String, $placeID: String!, $groupID: String!) {
    permissions(where: { id: { _neq: $id }, placeID: { _eq: $placeID }, groupID: { _eq: $groupID } }) {
      id
    }
  }
`;
