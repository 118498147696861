import { gql } from '@apollo/client/core';

export const DoorDeviceGatewayFieldsFragment = gql`
  fragment DoorDeviceGatewayFieldsFragment on qx_Door {
    deviceID
    devicePacsStatus
    devicePacsHWUpdate
    deviceConnectionStatus
    gatewaySyncStatus
    gatewayConnectionStatus
    gatewayPlaceID
  }
`;

export const DoorPermissionsFragment = gql`
  fragment DoorPermissionsFragment on qx_Permission {
    id
    name
    thumbnail
    thumbnail_sm
    group {
      id
      name
      toPeople {
        personID
      }
    }
    schedule {
      id
      name
    }
  }
`;

export const DoorInsideOutsideFragment = gql`
  fragment DoorInsideOutsideFragment on qx_Place {
    id
    name
    path
    hierarchy {
      name
    }
    permissions {
      ...DoorPermissionsFragment
    }
    videoSources {
      id
      name
    }
  }
  ${DoorPermissionsFragment}
`;

export const DoorSingleFragment = gql`
  fragment DoorSingleFragment on qx_Door {
    id
    type
    name
    canOperate
    description
    updatedAt
    enabled
    defaultVideoSourceID
    batteryLevel
    doorMonitorAlarm
    videoSource: VideoSource {
      id
      name
      place {
        id
        name
        hierarchy {
          name
        }
      }
    }
    accessTime
    openTime
    extendedAccessTime
    extendedOpenTime
    preAlarmTime
    lockAddress
    doorMonitorAddress
    # doorMonitorSupervise # computed field
    mode
    state # connection status
    alarm
    unlockScheduleID
    disabledByLicense
    deviceID
    ...DoorDeviceGatewayFieldsFragment
    device {
      id
      name
      description
      connectionStatus
      meta
      make
      model
      serial
      firmware
      deviceAddress
      deviceMac
      deviceUsername
      devicePassword
      port
      tls
      pacsStatus
      pacsHWUpdate
      pacsUpdateTime
      gateway {
        id
        name
        connectionStatus
        syncStatus
        place {
          id
          name
          path
        }
      }
    }
    place {
      id
      name
      path
      hierarchy {
        name
      }
    }
    inside {
      ...DoorInsideOutsideFragment
    }
    outside {
      ...DoorInsideOutsideFragment
    }
    unlockSchedule {
      id
      name
      blocks {
        id
        date
        duration
        weekdays
        toHolidaySets {
          holidaySetID
          scheduleBlockID
          holidaySet {
            id
            name
          }
        }
      }
      permissions {
        id
        name
      }
      #  added place to inform the schedule component on DoorViewConfigPage.vue
      place {
        id
        name
      }
    }
    doorMonitor
  }
  ${DoorInsideOutsideFragment}
  ${DoorDeviceGatewayFieldsFragment}
`;

/**
 * Query a Single Door by ID
 */
export const GQL_GET_DOOR_BY_ID = gql`
  query GQL_GET_DOOR_BY_ID($id: bigint!) {
    door(id: $id) {
      ...DoorSingleFragment
    }
  }
  ${DoorSingleFragment}
`;

/**
 * Subscribe to a Single Door by ID
 */
export const GQL_SUB_DOOR_BY_ID = gql`
  subscription GQL_SUB_DOOR_BY_ID($id: bigint!) {
    door(id: $id) {
      ...DoorSingleFragment
    }
  }
  ${DoorSingleFragment}
`;

/**
 * Unlocks the door for 5 secs
 */
// TODO :: do not hardcode the access time
export const GQL_MOMENTARY_UNLOCK_DOOR_BY_ID = gql`
  mutation GQL_MOMENTARY_UNLOCK_DOOR_BY_ID($id: String!) {
    accessDoor(doorID: $id, accessTime: "PT05S") {
      success
    }
  }
`;

/**
 * Retrieve an Array of Doors based off of the DeviceID.
 * from any modified state.
 */
export const DoorSearchFragment = gql`
  fragment DoorSearchFragment on qx_Door {
    id
    type
    name
    description
    canOperate
    mode
    enabled
    state
    alarm
    batteryLevel
    doorMonitor
    doorMonitorAddress
    # doorMonitorSupervise # computed field
    defaultVideoSourceID
    # device-level information, privileges-related
    ...DoorDeviceGatewayFieldsFragment
    unlockSchedule {
      id
      name
      thumbnail
    }
    place {
      id
      name
      hierarchy {
        name
      }
    }
    outside {
      id
      name
      hierarchy {
        name
      }
    }
    device {
      id
      deviceAddress
      connectionStatus
      pacsStatus
      pacsHWUpdate
      gateway {
        id
        name
        connectionStatus
        syncStatus
      }
    }
  }
  ${DoorDeviceGatewayFieldsFragment}
`;

export const GQL_DOOR_SEARCH = gql`
  query GQL_DOOR_SEARCH($where: qx_Door_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Door_order_by!]) {
    doors(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...DoorSearchFragment
    }
    doorAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${DoorSearchFragment}
`;

export const DoorSearchMiniFragment = gql`
  fragment DoorSearchMiniFragment on qx_Door {
    id
    name
    canOperate
    place {
      id
      name
      hierarchy {
        name
      }
    }
  }
`;

export const GQL_DOOR_SEARCH_MINI = gql`
  query GQL_DOOR_SEARCH_MINI($where: qx_Door_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Door_order_by!]) {
    doors(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...DoorSearchMiniFragment
    }
    doorAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${DoorSearchMiniFragment}
`;

export const GQL_DOOR_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_DOOR_SEARCH_SUBSCRIPTION(
    $where: qx_Door_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Door_order_by!]
  ) {
    doors(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...DoorSearchFragment
    }
  }
  ${DoorSearchFragment}
`;
