import { gql } from '@apollo/client/core';

export const ScheduleBlockFragment = gql`
  fragment ScheduleBlockFragment on qx_ScheduleBlock {
    id
    scheduleID
    date
    duration
    weekdays
    toHolidaySets {
      holidaySetID
      scheduleBlockID
      holidaySet {
        id
        name
      }
    }
  }
`;

export const ScheduleFragment = gql`
  fragment ScheduleFragment on qx_Schedule {
    id
    name
    description
    placeID
    place {
      id
      name
      hierarchy {
        name
      }
      toHomePlaces {
        customerPlaceID
        integratorPlaceID
      }
    }
    permissions {
      id
    }
    blocks {
      ...ScheduleBlockFragment
    }
  }
  ${ScheduleBlockFragment}
`;

export const ScheduleSearchFragment = gql`
  fragment ScheduleSearchFragment on qx_Schedule {
    id
    name
    description
    place {
      id
      name
      hierarchy {
        name
      }
      toHomePlaces {
        customerPlaceID
        integratorPlaceID
      }
    }
  }
`;

export const GQL_GET_SCHEDULE_BY_ID = gql`
  query GQL_GET_SCHEDULE_BY_ID($id: String!) {
    schedule(id: $id) {
      thumbnail
      ...ScheduleFragment
    }
  }
  ${ScheduleFragment}
`;

export const GQL_SUB_SCHEDULE_BY_ID = gql`
  subscription GQL_SUB_SCHEDULE_BY_ID($id: String!) {
    schedule(id: $id) {
      ...ScheduleFragment
    }
  }
  ${ScheduleFragment}
`;

export const GQL_SCHEDULE_SEARCH = gql`
  query GQL_SCHEDULE_SEARCH($where: qx_Schedule_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Schedule_order_by!]) {
    schedules(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...ScheduleSearchFragment
      thumbnail
    }
    scheduleAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${ScheduleSearchFragment}
`;

export const GQL_SCHEDULE_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_SCHEDULE_SEARCH_SUBSCRIPTION(
    $where: qx_Schedule_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Schedule_order_by!]
  ) {
    schedules(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...ScheduleSearchFragment
      # thumbnail ... uses google url, subscription creates unnecessary spam due to the url token constantly changing
    }
  }
  ${ScheduleSearchFragment}
`;
