import { gql } from '@apollo/client/core';

export const CredentialFragment = gql`
  fragment CredentialFragment on qx_Credential {
    id
    pin
    value
    validFrom
    validTo
    enabled
    personID
    status
  }
`;

export const GQL_GET_CREDENTIAL_BY_ID = gql`
  query getCredentialById($id: String!) {
    credential(id: $id) {
      ...CredentialFragment
    }
  }
  ${CredentialFragment}
`;

export const GQL_CREATE_CREDENTIALS = gql`
  mutation createCredentials($credentials: [qx_Credential_insert_input!]!) {
    createCredentials(objects: $credentials) {
      returning {
        ...CredentialFragment
      }
    }
  }
  ${CredentialFragment}
`;

export const GQL_UPDATE_CREDENTIAL_BY_ID = gql`
  mutation updateCredentialById($id: String!, $credential: qx_Credential_set_input!) {
    updateCredentials(where: { id: { _eq: $id } }, _set: $credential) {
      affected_rows
    }
  }
`;

export const GQL_DELETE_CREDENTIAL_BY_ID = gql`
  mutation deleteCredentialById($id: String!) {
    deleteCredentials(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const GQL_CREDENTIAL_SEARCH = gql`
  query GQL_CREDENTIAL_SEARCH(
    $where: qx_Credential_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_Credential_order_by!]
  ) {
    credentials(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...CredentialFragment
    }
    credentialAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${CredentialFragment}
`;

/**
 * Used to determine if the card number
 * on a credential is unique.
 */
export const GQL_CHECK_FOR_UNIQUE_CARD_NUMBER = gql`
  query checkForUniqueCredential($credentialID: String, $cardNumber: String!, $customerPlaceID: String!) {
    credentials(
      where: { id: { _neq: $credentialID }, value: { _like: $cardNumber }, customerPlaceID: { _eq: $customerPlaceID } }
    ) {
      id
    }
  }
`;

/**
 * Used to determine if the PIN
 * on a credential is unique.
 */
export const GQL_CHECK_FOR_UNIQUE_PIN = gql`
  query checkForUniquePin($credentialID: String, $pin: String!, $custPlaceID: String!) {
    credentials(
      where: { id: { _neq: $credentialID }, pin: { _like: $pin }, customerPlace: { id: { _eq: $custPlaceID } } }
    ) {
      id
    }
  }
`;

/**
 * Used to determine if a Site Code and PIN
 * combination exists for a Customer.
 */
export const GQL_CHECK_CREDENTIAL_FOR_SITE_CODE_PIN_COMBINATIONS = gql`
  query checkForUniqueSiteCodeAndPinCombination(
    $credentialID: String
    $siteCode: String!
    $pin: String!
    $custPlaceID: String!
  ) {
    credentials(
      where: {
        id: { _neq: $credentialID }
        value: { _like: $siteCode }
        pin: { _eq: $pin }
        customerPlace: { id: { _eq: $custPlaceID } }
      }
    ) {
      id
      value
      pin
      status
      person {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
