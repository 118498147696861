import { gql } from '@apollo/client/core';

export const UserSettingFragment = gql`
  fragment UserSettingFragment on qx_UserSetting {
    personID
    name
    value
    updatedAt
  }
`;

export const subscribeToAllUserSettingsUpdates = gql`
  subscription subscribeToAllUserSettingUpdates($id: String!) {
    userSetting(id: $id) {
      ...UserSettingFragment
    }
  }
  ${UserSettingFragment}
`;

export const getUserSetting = gql`
  query userSetting($personID: String!, $settingName: String!) {
    userSettings(where: { personID: { _eq: $personID }, name: { _eq: $settingName } }) {
      id
      value
      updatedAt
    }
  }
`;

export const subscribeToUserSetting = gql`
  subscription subscribeToUserSetting($personID: String!, $settingName: String!) {
    userSettings(where: { personID: { _eq: $personID }, name: { _eq: $settingName } }) {
      id
      value
      updatedAt
    }
  }
`;

export const updateUserSettings = gql`
  mutation updateSettings($personID: String!, $settingName: String!, $val: jsonb!) {
    createUserSettings(
      objects: { personID: $personID, name: $settingName, value: $val }
      on_conflict: { constraint: UserSetting_personID_name_key, update_columns: value }
    ) {
      affected_rows
      returning {
        name
        value
        updatedAt
      }
    }
  }
`;

///=================== layout settings

export const UserSettingsLayoutFragment = gql`
  fragment UserSettingsLayoutFragment on qx_UserSetting {
    id
    name
    value
  }
`;
