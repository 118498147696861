import { gql } from '@apollo/client/core';

export const snapshotsAndEventsStats = gql`
  query SnapshotsAndEventsStats(
    $path: String!
    $date: timestamptz!
    $dateMinus1: timestamptz!
    $dateMinus2: timestamptz!
    $dateMinus3: timestamptz!
    $dateMinus4: timestamptz!
    $dateMinus5: timestamptz!
    $dateMinus6: timestamptz!
    $dateMinus7: timestamptz!
  ) {
    dateSnapshotCount: snapshotAggregate(
      where: {
        _and: [
          { videoSource: { place: { path: { _like: $path } } } }
          { time: { _lt: $date } }
          { time: { _gte: $dateMinus1 } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus1SnapshotCount: snapshotAggregate(
      where: {
        _and: [
          { videoSource: { place: { path: { _like: $path } } } }
          { time: { _lt: $dateMinus1 } }
          { time: { _gte: $dateMinus2 } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus2SnapshotCount: snapshotAggregate(
      where: {
        _and: [
          { videoSource: { place: { path: { _like: $path } } } }
          { time: { _lt: $dateMinus2 } }
          { time: { _gte: $dateMinus3 } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus3SnapshotCount: snapshotAggregate(
      where: {
        _and: [
          { videoSource: { place: { path: { _like: $path } } } }
          { time: { _lt: $dateMinus3 } }
          { time: { _gte: $dateMinus4 } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus4SnapshotCount: snapshotAggregate(
      where: {
        _and: [
          { videoSource: { place: { path: { _like: $path } } } }
          { time: { _lt: $dateMinus4 } }
          { time: { _gte: $dateMinus5 } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus5SnapshotCount: snapshotAggregate(
      where: {
        _and: [
          { videoSource: { place: { path: { _like: $path } } } }
          { time: { _lt: $dateMinus5 } }
          { time: { _gte: $dateMinus6 } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus6SnapshotCount: snapshotAggregate(
      where: {
        _and: [
          { videoSource: { place: { path: { _like: $path } } } }
          { time: { _lt: $dateMinus6 } }
          { time: { _gte: $dateMinus7 } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateEventCount: eventAggregate(
      where: {
        _and: [
          { place: { path: { _like: $path } } }
          { time: { _lt: $date } }
          { time: { _gte: $dateMinus1 } }
          { type: { _eq: "Motion" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus1EventCount: eventAggregate(
      where: {
        _and: [
          { place: { path: { _like: $path } } }
          { time: { _lt: $dateMinus1 } }
          { time: { _gte: $dateMinus2 } }
          { type: { _eq: "Motion" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus2EventCount: eventAggregate(
      where: {
        _and: [
          { place: { path: { _like: $path } } }
          { time: { _lt: $dateMinus2 } }
          { time: { _gte: $dateMinus3 } }
          { type: { _eq: "Motion" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus3EventCount: eventAggregate(
      where: {
        _and: [
          { place: { path: { _like: $path } } }
          { time: { _lt: $dateMinus3 } }
          { time: { _gte: $dateMinus4 } }
          { type: { _eq: "Motion" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus4EventCount: eventAggregate(
      where: {
        _and: [
          { place: { path: { _like: $path } } }
          { time: { _lt: $dateMinus4 } }
          { time: { _gte: $dateMinus5 } }
          { type: { _eq: "Motion" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus5EventCount: eventAggregate(
      where: {
        _and: [
          { place: { path: { _like: $path } } }
          { time: { _lt: $dateMinus5 } }
          { time: { _gte: $dateMinus6 } }
          { type: { _eq: "Motion" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    dateMinus6EventCount: eventAggregate(
      where: {
        _and: [
          { place: { path: { _like: $path } } }
          { time: { _lt: $dateMinus6 } }
          { time: { _gte: $dateMinus7 } }
          { type: { _eq: "Motion" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
