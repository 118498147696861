import { gql } from '@apollo/client/core';

export const GQL_UPDATE_DEVICE_DIO_BY_ID = gql`
  mutation GQL_UPDATE_DEVICE_DIO_BY_ID($id: String!, $dio: qx_DeviceDIO_set_input!) {
    updateDeviceDIO(_set: $dio, pk_columns: { id: $id }) {
      id
      name
      type
      defaultState
    }
  }
`;

export const GQL_DIO_ACTIVATE_OUTPUT = gql`
  mutation GQL_DIO_ACTIVATE_OUTPUT($outputPointID: String!) {
    activateOutput(outputPointID: $outputPointID) {
      success
    }
  }
`;

export const GQL_DIO_DEACTIVATE_OUTPUT = gql`
  mutation GQL_DIO_DEACTIVATE_OUTPUT($outputPointID: String!) {
    deactivateOutput(outputPointID: $outputPointID) {
      success
    }
  }
`;
