import { gql } from '@apollo/client/core';

export const getEventTypes = gql`
  query getEventTypes {
    eventTypes {
      id: type
      type
      priority
      uniqueID
    }
  }
`;
