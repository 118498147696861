import { gql } from '@apollo/client/core';

export const GQL_GET_ACCESS_REPORT_INFO = gql`
  mutation GQL_GET_ACCESS_REPORT_INFO($type: ReportType!) {
    accessReportInfo(type: $type) {
      columns
      filterable
      sortable
      type
    }
  }
`;

export const AccessReportFragment = gql`
  fragment AccessReportFragment on AccessReport {
    columns
    rows
    totalCount
    type
  }
`;

export const GQL_GET_ACCESS_REPORT = gql`
  mutation GQL_GET_ACCESS_REPORT(
    $type: ReportType!
    $limit: Int
    $offset: Int
    $orderBy: [AccessReportOrderByInput]
    $filter: AccessReportFilterInput
    $options: AccessReportOptionsInput
  ) {
    accessReport(type: $type, limit: $limit, offset: $offset, orderBy: $orderBy, filter: $filter, options: $options) {
      ...AccessReportFragment
    }
  }
  ${AccessReportFragment}
`;

export const GQL_GET_ACCESS_REPORT_DOWNLOAD_URL = gql`
  mutation GQL_GET_ACCESS_REPORT_DOWNLOAD_URL(
    $type: ReportType!
    $limit: Int
    $offset: Int
    $orderBy: [AccessReportOrderByInput]
    $filter: AccessReportFilterInput
    $options: AccessReportOptionsInput
  ) {
    getDownloadAccessReportUrl(
      type: $type
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      filter: $filter
      options: $options
    ) {
      expiresAt
      url
    }
  }
`;
