import { gql } from '@apollo/client/core';

export const GQL_SEARCH_CAMERA_DOOR_PERSON_PLACE = gql`
  query GQL_SEARCH_CAMERA_DOOR_PERSON_PLACE(
    $whereCameras: qx_VideoSource_bool_exp
    $whereDoors: qx_Door_bool_exp
    $wherePlaces: qx_Place_bool_exp
    $wherePeople: qx_Person_bool_exp
  ) {
    videoSources(limit: 50, where: $whereCameras, order_by: [{ name: asc }]) {
      id
      name
      place {
        id
        name
        hierarchy {
          name
        }
      }
    }
    doors(limit: 50, where: $whereDoors, order_by: [{ name: asc }]) {
      id
      name
      place {
        id
        name
        hierarchy {
          name
        }
      }
    }
    people(limit: 50, where: $wherePeople, order_by: [{ lastName: asc }, { firstName: asc }]) {
      id
      lastName
      firstName
      place {
        id
        name
        hierarchy {
          name
        }
      }
    }
    places(limit: 50, where: $wherePlaces, order_by: [{ name: asc }]) {
      id
      name
      hierarchy {
        name
      }
    }
  }
`;

export const GQL_GET_CAMERA_DOOR_PERSON_PLACE = gql`
  query GQL_GET_CAMERA_DOOR_PERSON_PLACE(
    $Camera: [bigint!] = []
    $Door: [bigint!] = []
    $Person: [String!] = []
    $Place: [String!] = []
  ) {
    videoSources(where: { id: { _in: $Camera } }) {
      id
      name
    }
    doors(where: { id: { _in: $Door } }) {
      id
      name
    }
    people(where: { id: { _in: $Person } }) {
      id
      lastName
      firstName
    }
    places(where: { id: { _in: $Place } }) {
      id
      name
    }
  }
`;
