import { gql } from '@apollo/client/core';

export const ViewFragment = gql`
  fragment ViewFragment on qx_View {
    id
    settings
    name
    personID
    place {
      id
      name
      type
      hierarchy {
        name
      }
      defaultView {
        id
        name
      }
    }
  }
`;

export const ViewSearchFragment = gql`
  fragment ViewSearchFragment on qx_View {
    id
    settings
    name
    isDefault
    personID
    place {
      id
      name
      type
      hierarchy {
        name
      }
      defaultView {
        id
        name
      }
    }
  }
`;

export const ViewFormFragment = gql`
  fragment ViewFormFragment on qx_View {
    id
    name
    personID
    placeID
    place {
      id
      name
      defaultViewID
    }
  }
`;

export const GQL_GET_VIEW_BY_ID_AND_PATH = gql`
  query GQL_GET_VIEW_BY_ID_AND_PATH($id: String!, $path: String!) {
    views(where: { _and: [{ id: { _eq: $id } }, { place: { path: { _like: $path } } }] }) {
      ...ViewFragment
    }
  }
  ${ViewFragment}
`;

export const GQL_VIEW_SEARCH = gql`
  query GQL_VIEW_SEARCH($where: qx_View_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_View_order_by!]) {
    views(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...ViewSearchFragment
    }
    viewsAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${ViewSearchFragment}
`;

export const GQL_VIEW_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_VIEW_SEARCH_SUBSCRIPTION(
    $where: qx_View_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_View_order_by!]
  ) {
    views(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...ViewSearchFragment
    }
  }
  ${ViewSearchFragment}
`;

export const GQL_GET_VIEW_FORM = gql`
  query GQL_GET_VIEW_FORM($id: String!) {
    view(id: $id) {
      ...ViewFormFragment
    }
  }
  ${ViewFormFragment}
`;

export const GQL_UPDATE_VIEW_BY_ID = gql`
  mutation GQL_UPDATE_VIEW_BY_ID($id: String!, $view: qx_View_set_input) {
    updateView(pk_columns: { id: $id }, _set: $view) {
      id
    }
  }
`;

export const GQL_DELETE_VIEW_BY_ID = gql`
  mutation GQL_DELETE_VIEW_BY_ID($id: String!) {
    deleteView(id: $id) {
      id
    }
  }
`;
